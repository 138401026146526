

.background {
  border-radius: 0px;
  position: relative;
}

.main-title-heading {
  font-size: 95px;
}

.tag-line-heading {
  font-size: 46px;
  letter-spacing: 2px;
}


.button-heading {
  margin: 10px 25px;
}
.card-overlay-heading {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 7%;
}
.card-footer {
  border: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2% 3%;
}

@media (max-width: 1000px) {
  .main-title-heading {
    font-size: 60px;
  }
  
  .tag-line-heading {
    font-size: 26px;
  }
}

@media (max-width: 850px) {
  .main-title-heading {
    font-size: 70px;
  }
  
  .tag-line-heading {
    font-size: 30px;
  }

  .button-heading {
    font-size: 20px;
  }
}

@media (max-width: 750px) {
  .main-title-heading {
    font-size: 60px;
  }
  
  .tag-line-heading {
    font-size: 25px;
  }

  .button-heading {
    font-size: 18px;
  }
}

@media (max-width: 680px) {
  .main-title-heading {
    font-size: 50px;
  }
  
  .tag-line-heading {
    font-size: 22px;
  }

  .button-heading {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .main-title-heading {
    font-size: 45px;
  }
  
  .tag-line-heading {
    font-size: 20px;
  }

  .button-heading {
    font-size: 15px;
    margin: 0px 10px;
  }

  .heading {
    padding: 0px;
  }
}

@media (max-width: 560px) {
  .main-title-heading {
    font-size: 42px;
  }
  
  .tag-line-heading {
    font-size: 18px;
  }

  .button-heading {
    font-size: 12px;
    margin: 0px 10px;
  }

  .card-footer{
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  .main-title-heading {
    font-size: 40px;
  }
  
  .tag-line-heading {
    font-size: 16px;
  }

  .button-heading {
    font-size: 12px;
    margin: 0px 10px;
  }

  .heading {
    padding: 0px;
  }
  
}

@media (max-width: 450px) {
  .main-title-heading {
    font-size: 32px;
  }
  
  .tag-line-heading {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .button-heading {
    font-size: 8px;
    margin: 0px 10px;
    padding: 4px 10px;
  }

  .heading {
    padding: 0px;
  }
}

@media (max-width: 400px) {
  .main-title-heading {
    font-size: 30px;
  }
  
  .tag-line-heading {
    font-size: 15px;
    margin-bottom: 3px;
  }

  .button-heading {
    font-size: 5px;
    margin: 0px 7px;
  }

  .heading {
    padding: 0px;
  }
  
  .card-footer{
    font-size: 8px;
  }
}

@media (max-width: 330px) {
  .main-title-heading {
    font-size: 25px;
  }
  
  .tag-line-heading {
    font-size: 12px;
    margin-bottom: 2px;
  }

  .button-heading {
    font-size: 4px;
    margin: 0px 7px;
    padding: 4px 8px;
  }

  .heading {
    padding: 0px;
  }
  
  .card-footer{
    font-size: 9px;
  }
}

@media (max-width: 300px) {
  .main-title-heading {
    font-size: 25px;
  }
  
  .tag-line-heading {
    font-size: 10px;
    margin-bottom: 2px;
  }
}

@media (max-width: 260px) {
  .main-title-heading {
    font-size: 20px;
  }
  
  .tag-line-heading {
    font-size: 8px;
    margin-bottom: 1px;
  }
}
