.service-provide-container {
  /* margin: 5% 8%; */
  margin: 4% 4%
}

.room-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6% auto;
}

.carousel-img {
  width: 95%;
}