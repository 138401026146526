
.tagline-heading {
  font-size: 50px;
  text-decoration:solid;
  color: black;
  margin: auto;
  text-align: center;
  margin: 3% 7%;
  font-weight: 600;
}
.container {
  margin: 20px auto;
}

.about-container-1 {
  display: grid;
  grid-template-columns: auto 50%;
  justify-content: space-between;
  grid-column-gap: 30px ;
}

.about-container-2 {
  margin: 1% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-text {
  font-size: 23px;
  margin:7px auto;
}

.design-text {
  font-size: 30px;
  font-weight: 600;
}

.welcome-text {
  font-size: 45px;
  font-weight: bold;
}

.contact-text {
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-icon {
  width: 10%;
  margin: 0% 1%;
  cursor: pointer;
}

.location-svg-icon {
  width: 3%;
  margin: 0% 1%;
}

.service-provide {
  font-weight: bold;
  font-size: 20px;
}

.container-item-1 {
  display: flex;
  align-items: center;
}

/* work section css */
.work-container-1 {
  display: grid;
  grid-template-columns: 40% 40%;
  width: 75%;
  margin: 0px auto;
  justify-content: space-between;
}

.product-button, .service-button {
  margin: 15px;
  width: 100px;
}

.product-item, .service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media (max-width: 1040px) {
  .about-text {
    font-size: 20px;
  }
  
  .design-text {
    font-size: 25px;
    font-weight: 600;
  }
  
  .welcome-text {
    font-size: 38px;
    font-weight: bold;
  }
}

@media (max-width: 1000px) {
  .tagline-heading {
    font-size: 35px;
  }
  .about-text {
    font-size: 15px;
  }
}

@media (max-width: 930px) {
  
  .about-text {
    font-size: 12px;
  }
  
  .design-text {
    font-size: 22px;
    font-weight: 600;
  }
  
  .welcome-text {
    font-size: 35px;
    font-weight: bold;
  }

  .about-text {
    font-size: 14px;
  }

  .contact-text {
    font-weight: bold;
    font-size: 15px;
  }
  
  .service-provide {
    font-weight: bold;
    font-size: 15px;
  }
}

@media (max-width: 770px) {
  .tagline-heading {
    font-size: 30px;
  }

  .about-container-1 img {
    /* height: 80%; */
  }

  .design-text {
    font-size: 22px;
    font-weight: 550;
  }
  
  .welcome-text {
    font-size: 33px;
    font-weight:650;
  }
  .about-container-1 img {
    /* height: 75%; */
  }
  .about-text {
    font-size: 14px;
  }
  .service-button, .product-button {
    font-size: 14px;
  }
  
}

@media (max-width: 730px) {
  .work-container-1 {
    grid-template-columns: 100%;
    width: 65%;
    margin: 0px auto;
    justify-content: space-between;
  }
}

@media (max-width: 630px) {
  .design-text {
    font-size: 20px;
    font-weight: 550;
  }
  
  .welcome-text {
    font-size: 33px;
    font-weight:650;
  }
  .about-container-1 img {
    height: 75%;
    /* width:100%; */
  }

  .about-text {
    margin-bottom: 2px;
  }
}

@media (max-width: 660px) {
  .tagline-heading {
    font-size: 28px;
  }
  .service-button, .product-button {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .tagline-heading {
    font-size: 24px;
  }

  .design-text {
    font-size: 18px;
    font-weight: 500;
  }
  
  .welcome-text {
    font-size: 30px;
    font-weight:620;
  }
  .about-container-1 img {
    /* height: 75%; */
  }

  .about-text {
    margin-bottom: 2px;
  }

}

@media (max-width: 550px) {
  .tagline-heading {
    font-size: 20px;
    margin: 2% 15%;
    font-weight: 600;
  }
  .about-container-1 {
    grid-template-columns: auto;
    margin: 0px 7%;
  }
  .about-text{
    font-size: 16px;
  }

  .about-container-1 img {
    height: 100%;
  }

  .service-button, .product-button {
    font-size: 12px;
  }
  
}

@media (max-width: 420px) {
  .tagline-heading {
    font-size: 17px;
    margin: 2% 15%;
    font-weight: 550;
  }

  .design-text {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 7px;
  }
  
  .welcome-text {
    font-size: 23px;
    font-weight:620;
    margin-bottom: 0px;
  }

  .about-text {
    font-size: 14px;
  }

  .contact-text {
    font-size: 13px;
  }
  
  .service-provide {
    font-size: 13px;
  }

  .service-button, .product-button {
    font-size: 10px;
  }

  
}

@media (max-width: 380px) {

  .service-button, .product-button {
    font-size: 10px;
  }

  .tagline-heading {
    font-size: 14px;
    margin: 2% 15%;
    font-weight: 500;
  }

  .design-text {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 7px;
  }
  
  .welcome-text {
    font-size: 20px;
    font-weight:620;
    margin-bottom: 0px;
  }

  .about-text {
    font-size: 12px;
  }

  .contact-text {
    font-size: 11px;
  }
  
  .service-provide {
    font-size: 11px;
  }
}

@media (max-width: 300px) {
  .tagline-heading {
    font-size: 13px;
    margin: 2% 15%;
    font-weight: 500;
  }

  .service-button, .product-button {
    font-size: 8px;
  }
}